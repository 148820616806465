import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import { colorPalette } from "../../config/theme";

interface SankeyNode {
  name: string;
  category: string;
  value: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
  index?: number;
}

interface SankeyLink {
  source: number | SankeyNode;
  target: number | SankeyNode;
  value: number;
  width?: number;
}

// interface SankeyData {
//   nodes: SankeyNode[];
//   links: SankeyLink[];
// }

interface SankeyDiagramProps {
  data: any;
  width: number;
  height: number;
}

const COLORS = {
  education: colorPalette[0],
  judicial: "#22c55e",
  government: "#ef4444",
  inHouse: "#3b82f6",
  lawFirm: "#8b5cf6",
};

export const SankeyDiagram: React.FC<SankeyDiagramProps> = ({
  data,
  width,
  height,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    content: string;
    x: number;
    y: number;
  }>({ visible: false, content: "", x: 0, y: 0 });
  // @ts-ignore
  useEffect(() => {
    if (!svgRef.current || !data.nodes.length) return;

    // Clear previous content
    d3.select(svgRef.current).selectAll("*").remove();

    const sankeyGenerator = sankey<SankeyNode, SankeyLink>()
      .nodeWidth(15)
      .nodePadding(10)
      .extent([
        [1, 1],
        [width - 1, height - 5],
      ]);

    const sankeyData = sankeyGenerator({
      nodes: JSON.parse(JSON.stringify(data.nodes)),
      links: JSON.parse(JSON.stringify(data.links)),
    });

    const svg = d3.select(svgRef.current);

    // Add links
    const linkGroup = svg.append("g");

    linkGroup
      .selectAll("path")
      .data(sankeyData.links)
      .join("path")
      .attr("d", sankeyLinkHorizontal())
      .attr("fill", "none")
      .attr("stroke", (d) => {
        const sourceNode = (
          typeof d.source === "object"
            ? d.source
            : sankeyData.nodes[d.source as number]
        ) as SankeyNode;
        return (
          COLORS[sourceNode.category as keyof typeof COLORS] || colorPalette[2]
        );
      })
      .attr("stroke-width", (d) => Math.max(1, d.width || 1))
      .attr("opacity", 0.5)
      .on("mouseover", function (event, d) {
        setTooltip({
          visible: true,
          // @ts-ignore
          content: `${d.source.name} → ${d.target.name}: ${d.value}`,
          x: event.pageX,
          y: event.pageY,
        });
        d3.select(this).attr("opacity", 1);
      });

    // Add nodes
    const nodeGroup = svg.append("g");

    nodeGroup
      .selectAll("rect")
      .data(sankeyData.nodes)
      .join("rect")
      .attr("x", (d) => d.x0 || 0)
      .attr("y", (d) => d.y0 || 0)
      .attr("height", (d) => (d.y1 || 0) - (d.y0 || 0))
      .attr("width", (d) => (d.x1 || 0) - (d.x0 || 0))
      .attr(
        "fill",
        (d) => COLORS[d.category as keyof typeof COLORS] || colorPalette[1]
      )
      .on("mouseover", function (event, d) {
        setTooltip({
          visible: true,
          content: `${d.name}: ${d.value}`,
          x: event.pageX,
          y: event.pageY,
        });
        d3.select(this).attr("opacity", 0.8);
      });

    // Add labels
    svg
      .append("g")
      .selectAll("text")
      .data(sankeyData.nodes)
      .join("text")
      .attr("x", (d) =>
        (d.x0 || 0) < width / 2 ? (d.x1 || 0) + 6 : (d.x0 || 0) - 6
      )
      .attr("y", (d) => ((d.y1 || 0) + (d.y0 || 0)) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", (d) => ((d.x0 || 0) < width / 2 ? "start" : "end"))
      .text((d) => `${d.name} (${d.value}%)`)
      .attr("font-size", "10px")
      .attr("fill", "black");

    // Tooltip for interactivity
    const tooltipDiv = d3
      // @ts-ignore
      .select(svgRef.current.parentNode)
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "white")
      .style("border", "1px solid black")
      .style("padding", "5px")
      .style("border-radius", "5px");

    // Update tooltip visibility based on state
    const updateTooltipVisibility = () => {
      if (tooltip.visible) {
        tooltipDiv
          .style("visibility", "visible")
          .html(tooltip.content)
          .style("top", `${tooltip.y}px`)
          .style("left", `${tooltip.x}px`);
      } else {
        tooltipDiv.style("visibility", "hidden");
      }
    };

    updateTooltipVisibility();

    // Hide tooltip on mouse move outside of elements
    svg.on("mousemove", () => {
      setTooltip({ ...tooltip, visible: false });
    });

    return () => tooltipDiv.remove(); // Cleanup on unmount
  }, [data, width, height, tooltip]);

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      className="overflow-visible"
    />
  );
};
