import React, { useEffect, useState } from "react";
import { Skeleton, Row, Col, Card, Statistic } from "antd";

const DashboardSkeleton = () => {
  const [loading, setLoading] = useState(true);

  // Simulate a loading state (replace with your actual data fetching logic)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a 2-second load time
  }, []);

  return (
    <div>
      {/* Heading or title for the dashboard */}
      <h2 style={{ marginBottom: "20px" }}>Dashboard</h2>

      {/* Row of placeholders */}
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Skeleton active loading={loading} paragraph={{ rows: 1 }}>
              <Statistic title="UG Degree Matching" value={80} />
            </Skeleton>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Skeleton active loading={loading} paragraph={{ rows: 1 }}>
              <Statistic title="UG Tier Matching" value={70} />
            </Skeleton>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Skeleton active loading={loading} paragraph={{ rows: 1 }}>
              <Statistic title="Career Path Progression" value={60} />
            </Skeleton>
          </Card>
        </Col>
      </Row>

      {/* Another Row for charts or more statistics */}
      <Col style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
            </Card>
          </Col>
        </Row>
      </Col>

      {/* Add more sections as required */}
    </div>
  );
};

export default DashboardSkeleton;
