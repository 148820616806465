import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useLazyGetUserDetailsQuery } from "../Store/Slices/UserApiSlice"; // Assuming this is where your API slice is defined

interface Auth {
  accessToken: string;
  access_level: string;
  company_id: number;
  email: string;
  expiresAt: number;
  first_name: string;
  id: number;
  last_name: string;
  title: string;
}

interface AuthContextType {
  auth: Auth | null;
  setAuthenticated: (accessToken: string) => void;
  clearAuthentication: () => void;
  localAuth: Auth | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [localAccessToken] = useState<string | null>(() =>
    localStorage.getItem("accessToken")
  );
  //eslint-disable-next-line
  const [fetchUserDetails, { data: userData, error, isFetching }] =
    useLazyGetUserDetailsQuery();

  const setAuthenticated = (accessToken: string) => {
    localStorage.setItem("accessToken", accessToken);
    fetchUserDetails(); // Fetch user details whenever a new token is set
  };

  const clearAuthentication = () => {
    localStorage.removeItem("accessToken");
    setAuth(null);
  };

  useEffect(() => {
    const initializeAuth = async () => {
      if (localAccessToken) {
        try {
          const response = (await fetchUserDetails(
            undefined,
            true
          ).unwrap()) as any;

          const userDetails = response?.data || {};
          console.log("🚀 ~ initializeAuth ~ userDetails:", userDetails);
          setAuth({
            ...userDetails,
            accessToken: localAccessToken,
            expiresAt: Date.now() + 86400000,
          });
        } catch (err) {
          console.error("Error fetching user details", err);
          clearAuthentication();
        }
      }
    };

    initializeAuth();
  }, [localAccessToken, fetchUserDetails]);

  return (
    <AuthContext.Provider
      value={{ auth, setAuthenticated, clearAuthentication, localAuth: auth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
