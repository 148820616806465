import { Card, Col, Progress, Row } from "antd";
import WorldMapComponent from "../india-map";

import ApexPieChart from "../charts/top-skills-pie-chart";

import ApexChartDummy from "../charts/profile-dummy-chart";
import { CareerFlow } from "../CareerFlow";
import DesiredCareerPaths from "./DesiredCareerPaths";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import { colorPalette } from "../../config/theme";
import { FaBuilding, FaIndustry } from "react-icons/fa";

const { Title, Paragraph } = Typography;
const OutputComponent = () => {
  const {
    desiredTitle,

    topJobLocations,
    // desiredTitleCount,
    totalCount,
    statisticsData,
    percentageStatistics,
    sankeyData,
    topSkills,
    desiredCareerPaths,
    experienceRange,
  } = useSelector((state: any) => state.desiredInsights);

  const desiredTitleMatch = statisticsData?.row_counts_desired_title;
  const UGDegreeMatch = statisticsData?.row_counts_ug_degree;

  const selectedCompanySizeMatch = statisticsData?.row_counts_company_size;
  const selectedCompanySectorMatch = statisticsData?.row_counts_company_sector;
  // const UGDegreeAndAdditional = statisticsData?.row_counts_ug_and_add_title;
  // const UGDegreeAndUGTierMatch =
  //   statisticsData?.row_counts_ug_degree_and_ug_tier;
  const UGDegreeAndDesiredMatch =
    statisticsData?.row_counts_ug_and_desired_title;
  // const UgDegreeAndCompanyMatch =
  //   statisticsData?.row_counts_ug_and_desired_company;

  if (!desiredTitle) {
    return <div></div>;
  }

  return (
    <div className="output-container">
      <div className="">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={16}>
            <Card style={{ height: "635px" }}>
              <Row gutter={[16, 16]}>
                <Col md={16}>
                  {/* <Title level={4}>Top Skills</Title> */}
                  <ApexPieChart data={topSkills || []} />
                  <Row justify="center" align="middle">
                    <Paragraph className="text-gray text-center">
                      Top skills you need to become the {desiredTitle}
                    </Paragraph>
                  </Row>
                </Col>

                <Col md={8}>
                  <Card>
                    <Progress
                      type="circle"
                      strokeColor={colorPalette[0]}
                      strokeWidth={8}
                      width={150}
                      percent={100}
                      format={() => (
                        <span className="percentage-text text-black">
                          {percentageStatistics.percentageWithTitle}%
                        </span>
                      )}
                    />
                    <Paragraph>
                      Percentage of profiles similar to you became the{" "}
                      {desiredTitle}
                    </Paragraph>

                    <Progress
                      type="circle"
                      strokeColor={colorPalette[0]}
                      strokeWidth={8}
                      width={150}
                      percent={100}
                      format={() => (
                        <span className="percentage-text text-black">
                          {percentageStatistics.averageJobChanges}
                        </span>
                      )}
                    />
                    <Paragraph>
                      Average job changes required for your profile to become
                      the {desiredTitle}
                    </Paragraph>

                    {/* <Progress
                      type="circle"
                      strokeWidth={8}
                      width={150}
                      strokeColor={colorPalette[0]}
                      percent={100}
                      format={() => (
                        <span className="percentage-text text-black">
                          {percentageStatistics.yearsTaken}
                        </span>
                      )}
                    />
                    <Paragraph>
                      Average years required to become the {desiredTitle}
                    </Paragraph> */}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} lg={8}>
            <Card>
              <Paragraph className="text-gray ">
                Percentage of desired title profiles in selected UG Degree
              </Paragraph>
              <span className="percentage-text text-primary-2">
                {Math.round((UGDegreeAndDesiredMatch / UGDegreeMatch) * 100) ||
                  0}
                %
              </span>
            </Card>

            <Card style={{ marginTop: "15px" }} title="Top Cities">
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col span={16}>
                  <WorldMapComponent topCities={topJobLocations} />
                </Col>
                <Col span={8}>
                  {topJobLocations.map((item: any, index: any) => (
                    <p key={item.location} style={{ fontWeight: "bold" }}>
                      {index + 1}.{" "}
                      <span className="text-primary-2">{item.location}</span>
                    </p>
                  ))}
                </Col>
              </Row>
              <Paragraph>
                Top cities match for your profile to become a {desiredTitle}
              </Paragraph>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={16}>
            <Card style={{ height: "225px" }}>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={4} className="text-gray">
                    Your profile matching with the title{" "}
                    <span className="text-primary-2">{desiredTitle}</span>
                  </Title>
                  <span className="text-primary percentage-text-large">
                    {Math.round((desiredTitleMatch / totalCount) * 100)}%
                  </span>
                </Col>
                <Col span={12}>
                  <ApexChartDummy />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <div className={` bg-light`}>
                    <FaBuilding className="icon text-primary-2" />
                  </div>
                  <Paragraph
                    className="text-gray "
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    Company size match
                  </Paragraph>
                  <span className="text-primary percentage-text">
                    {Math.round((selectedCompanySizeMatch / totalCount) * 100)}%
                  </span>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <div className={` bg-light`}>
                    <FaIndustry className="icon text-secondary" />
                  </div>
                  <Paragraph
                    className="text-gray "
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    Company Sector match
                  </Paragraph>
                  <span className="text-primary percentage-text">
                    {Math.round(
                      (selectedCompanySectorMatch / totalCount) * 100
                    )}
                    %
                  </span>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <DesiredCareerPaths
          paths={desiredCareerPaths}
          desiredTitle={desiredTitle}
        />

        <CareerFlow
          data={sankeyData || []}
          desiredTitle={desiredTitle}
          education={"BE"}
          experienceRange={experienceRange || ""}
        />
      </div>
    </div>
  );
};

export default OutputComponent;
