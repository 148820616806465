import React from "react";
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "../../Store/Slices/UserApiSlice";
import {
  Form,
  Button,
  Card,
  Typography,
  Skeleton,
  message,
  Select,
} from "antd";

import { useGetDashboardQuery } from "../../Store/Slices/DashboardApiSlice";

const { Title } = Typography;
const { Option } = Select;

const UserProfile = () => {
  const { data, isLoading }: any = useGetUserProfileQuery();
  const [updateProfile, { isLoading: updateLoading }] =
    useUpdateUserProfileMutation();
  const { refetch } = useGetDashboardQuery();

  const [form] = Form.useForm();

  // Update form when data is loaded
  React.useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({
        ugDegree: data.data.ug_degree,
        ugTier: data.data.ug_tier,
        skills: data.data.skills,
      });
    }
  }, [data, form]);

  const handleSubmit = async (values: any) => {
    try {
      await updateProfile(values).unwrap();
      refetch();
      message.success("Profile updated successfully!");
    } catch (err) {
      message.error("Failed to update profile");
      console.error("Failed to update profile:", err);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <Skeleton active paragraph={{ rows: 4 }} />
      </Card>
    );
  }

  return (
    <Card
      title={
        <Title level={4} style={{ margin: 0 }}>
          User Profile
        </Title>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={data?.data}
      >
        <Form.Item
          label="UG Degree"
          name="ugDegree"
          rules={[{ required: true, message: "Please input your UG degree!" }]}
        >
          <Select>
            <Option value="BE">BE</Option>
            <Option value="BTech">BTech</Option>
            <Option value="BSc">BSc</Option>
            {/* Add more degree options as needed */}
          </Select>
        </Form.Item>

        <Form.Item
          label="UG Tier"
          name="ugTier"
          rules={[{ required: true, message: "Please select your UG tier!" }]}
        >
          <Select>
            <Option value="1">Tier 1</Option>
            <Option value="2">Tier 2</Option>
            <Option value="3">Tier 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Skills"
          name="skills"
          rules={[
            { required: true, message: "Please select at least one skill!" },
          ]}
        >
          <Select mode="multiple" placeholder="Select your skills">
            <Option value="finance">Finance</Option>
            <Option value="marketing">Marketing</Option>
            <Option value="technology">Technology</Option>
            <Option value="operations">Operations</Option>
            {/* Add more skill options as needed */}
          </Select>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={updateLoading}
        >
          Save changes
        </Button>
      </Form>
    </Card>
  );
};

export default UserProfile;
