import React from "react";
import { Card, Col, Steps, Typography } from "antd";

const { Paragraph, Title } = Typography;

const { Step } = Steps;

interface CareerPathsProps {
  paths: Record<
    string,
    Array<{
      jobTitle: string;
      yearsToAcquire: string;
      jobChanges: string;
      otherMetrics: string;
    }>
  >;
  desiredTitle: string;
}

const DesiredCareerPaths: React.FC<CareerPathsProps> = ({
  paths,
  desiredTitle,
}) => (
  <Card title="Desired Career Paths" style={{ marginTop: 20 }}>
    <Paragraph className="text-gray">
      These are the potential career paths that you can take to become the{" "}
      {desiredTitle}. Explore these options to identify opportunities that best
      match your skills and aspirations
    </Paragraph>
    {Object.entries(paths).map(([pathKey, pathDetails], index) => (
      <Col span={24} key={pathKey} style={{ marginBottom: "20px" }}>
        <Title
          level={4}
          className="text-primary-2"
          style={{ marginBottom: "20px" }}
        >
          Path {index + 1}
        </Title>

        <Steps direction="horizontal" size="small" current={Infinity}>
          {pathDetails.map((detail, index) => (
            <Step
              key={index}
              title={detail.jobTitle}
              status="process"
              description={
                <div className="text-left">
                  <div>{detail.yearsToAcquire}</div>
                  <div>Job Changes: {detail.jobChanges}</div>
                  <div>{detail.otherMetrics}</div>
                </div>
              }
            />
          ))}
        </Steps>
      </Col>
    ))}
  </Card>
);

export default DesiredCareerPaths;
