import { apiSlice } from "./ApiSlice"
import { AUTH_URL } from "../../config/Constants"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/signin`,
        method: "POST",
        body,
      }),
    }),
    registerUser: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/register`,
        method: "POST",
        body: data,
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: `${AUTH_URL}/signout`,
        method: "POST",
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/change-password`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/forgot-password`,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/reset-password`,
        method: "POST",
        body,
      }),
      // @ts-ignore
      params: (body) => ({
        id: body.id,
        token: body.token,
      }),
    }),
    sendOtp: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/send-otp`,
        method: "POST",
        body,
      }),
      // @ts-ignore
      params: (body) => ({
        id: body.id,
        token: body.token,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: `${AUTH_URL}/verify-otp`,
        method: "POST",
        body,
      }),
      // @ts-ignore
      params: (body) => ({
        id: body.id,
        token: body.token,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterUserMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
} = authApiSlice
