import React, { ReactElement, useState, useEffect } from "react";
import {
  Button,
  Input,
  Typography,
  Form,
  Divider,
  Space,
  Row,
  Col,
  Card,
  Radio,
  message,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  useLoginMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
} from "../../Store/Slices/AuthApiSlice";
import { useAuth } from "../../context/userContext";
import { dashboard_path } from "../../config/config";

const Login = (): ReactElement => {
  const { setAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [loginMethod, setLoginMethod] = useState<"password" | "otp">(
    "password"
  );
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [login, { isLoading: loading }] = useLoginMutation();
  const [sendOtp, { isLoading: sendOtploading }] = useSendOtpMutation();
  const [verifyOtp, { isLoading: verifyOtploading }] = useVerifyOtpMutation();

  const handleSendOtp = async () => {
    const email = form.getFieldValue("email");
    if (!email) {
      messageApi.error("Please enter your email!");
      return;
    }
    try {
      await sendOtp({ email }).unwrap();
      messageApi.success("OTP sent to your email");
      setOtpSent(true);
      setTimer(30); // Start countdown
    } catch (error) {
      messageApi.error("Error sending OTP");
    }
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      handleSendOtp();
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleFinish = async (values: any) => {
    try {
      if (loginMethod === "password") {
        const res = await login({
          email: values.email,
          password: values.password,
        }).unwrap();
        setAuthenticated(res);
        messageApi.success("Logged in successfully");

        localStorage.setItem("accessToken", res.accessToken);
        navigate(dashboard_path);
      } else {
        const res = await verifyOtp({
          email: values.email,
          otp: values.otp,
        }).unwrap();
        setAuthenticated(res);
        messageApi.success("Logged in successfully");
        navigate(dashboard_path);
      }
    } catch (error: any) {
      messageApi.error(error?.data?.error_msg || "Login failed");
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "80vh" }}>
      {contextHolder}
      <Col xs={22} sm={16} md={12} lg={10} xl={8}>
        <Card bordered style={{ borderRadius: 8 }}>
          <Typography.Title
            level={3}
            style={{ textAlign: "center", marginBottom: 20 }}
          >
            Login
          </Typography.Title>
          <Radio.Group
            value={loginMethod}
            onChange={(e) => setLoginMethod(e.target.value)}
            style={{
              display: "none",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <Radio.Button value="password">Password</Radio.Button>
            <Radio.Button value="otp">OTP</Radio.Button>
          </Radio.Group>
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email!" }]}
            >
              <Input prefix={<MailOutlined />} placeholder="Enter your email" />
            </Form.Item>
            {loginMethod === "password" && (
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  prefix={<LockOutlined />}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            )}
            {loginMethod === "otp" && otpSent && (
              <Form.Item
                label="OTP"
                name="otp"
                rules={[{ required: true, message: "Please enter the OTP!" }]}
              >
                <Input placeholder="Enter the OTP" prefix={<LockOutlined />} />
              </Form.Item>
            )}
            {loginMethod === "otp" && !otpSent && (
              <Form.Item>
                <Button
                  type="primary"
                  block
                  loading={sendOtploading}
                  onClick={handleSendOtp}
                  disabled={sendOtploading}
                >
                  Send OTP
                </Button>
              </Form.Item>
            )}
            {loginMethod === "otp" && otpSent && (
              <Space
                direction="vertical"
                size="small"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                {timer > 0 ? (
                  <Typography.Text>Resend OTP in {timer}s</Typography.Text>
                ) : (
                  <Typography.Link onClick={handleResendOtp}>
                    Resend OTP
                  </Typography.Link>
                )}
              </Space>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loading || verifyOtploading}
                disabled={loading || verifyOtploading}
              >
                {loginMethod === "password" ? "Log in" : "Verify OTP"}
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          {loginMethod === "password" && (
            <Typography.Link
              href="/authentication/forgot-password"
              style={{ float: "right" }}
            >
              Forgot password?
            </Typography.Link>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
