import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";

import PageNotFound from "../components/misc/page-not-found";

import {
  dashboard_path,
  desired_path,
  home_path,
  login_path,
  register_path,
  user_profile_path,
} from "../config/config";
import ChooseDashboardType from "../pages/ChooseDashboardType";
import ProfileSetupPage from "../pages/ProfileSetupPage";
import DashboardPageNew from "../pages/DashboardPage";
import DesiredPathPage from "../pages/DesiredPath";
import UserProfilePage from "../pages/UserProfilePage";
import RegisterPage from "../pages/RegisterPage";
import LoginPage from "../pages/LoginPage";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={home_path}
          element={
            <>
              <HomePage />
            </>
          }
        />
        <Route path={login_path} element={<LoginPage />} />
        <Route path={register_path} element={<RegisterPage />} />

        <Route path={desired_path} element={<DesiredPathPage />} />
        <Route path={dashboard_path} element={<DashboardPageNew />} />

        <Route path={"/configure"} element={<ChooseDashboardType />} />

        <Route path={"/profile-setup"} element={<ProfileSetupPage />} />
        <Route path={user_profile_path} element={<UserProfilePage />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
