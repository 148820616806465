import React from "react";
import "./App.css";
import AppRoute from "./routes/AppRoute";

import { ConfigProvider } from "antd";
import { globalStyles, theme } from "./config/theme";
import { createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import store from "./Store/Store";
import { AuthProvider } from "./context/userContext";

const GlobalStyle = createGlobalStyle`
  ${globalStyles}
`;

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider theme={theme}>
          <GlobalStyle />
          <AuthProvider>
            <AppRoute />
          </AuthProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
