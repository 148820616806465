import React from "react";
import { SankeyDiagram } from "./charts/sankeyDiagram";
import { Divider, Typography, Space, Card } from "antd";

const { Title, Text } = Typography;

interface CareerFlowProps {
  data: any[];
  desiredTitle: any;
  education: any;
  experienceRange: any;
}

export const CareerFlow: React.FC<CareerFlowProps> = ({
  data,
  desiredTitle,
  education,
  experienceRange,
}) => {
  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Card title="Possible Career Paths">
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <SankeyDiagram data={data} width={800} height={500} />
        </div>

        <div style={{ textAlign: "left" }}>
          <Divider />
          <Title level={3} style={{ marginBottom: 16 }}>
            Path Analysis
          </Title>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Text>
              The most common educational background is{" "}
              <span className="text-primary">{education}</span>. The average
              years of experience are{" "}
              <span className="text-primary">{experienceRange} years</span>.
              {/* , and
              the typical number of role changes is approximately{" "}
              <span className="text-primary">
                {Math.round(
                  data.reduce((acc, curr) => acc + curr.switches, 0) /
                    data.length
                )}
              </span>  */}
            </Text>
          </Space>
        </div>
      </Card>
    </div>
  );
};
