import { Button, Col, Form, message, Row, Select } from "antd";

import {
  DesiredCompanyOptions,
  companySectorsOptions,
  companySizeOptions,
  customJobOptions,
} from "../constants/input-options";
import { useGetDesiredInsightsMutation } from "../../Store/Slices/DashboardApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDesiredInsights } from "../../Store/Slices/DesiredInsightsSlice";
import { useEffect } from "react";
import { setFormData } from "../../Store/Slices/InputFormSlice";

const { Option } = Select;

const InputComponent = () => {
  const [getDesiredInsights, { isLoading }] = useGetDesiredInsightsMutation();
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Ant Design form instance
  console.log("🚀 ~ InputComponent ~ form:", form);
  const storedFormData = useSelector((state: any) => state.formData); // Replace `state.formData` with your actual Redux state path

  // Pre-fill form values from Redux when the component mounts
  useEffect(() => {
    form.setFieldsValue(storedFormData);
  }, [storedFormData, form]);

  const handleSubmit = async (values: any) => {
    if (!values.desiredTitle) {
      message.error("Please select a desired title");
      return;
    }

    try {
      const response = await getDesiredInsights({
        desiredTitle: values.desiredTitle,
        desiredCompanySize: values.desiredCompanySize,
        desiredCompanySector: values.desiredCompanySector,
        desiredCompanies: values.desiredCompanies,
      }).unwrap();

      if (response.success) {
        dispatch(
          setDesiredInsights({
            desiredTitle: response.data.desiredTitle,
            desiredTitleCount: response.data.desiredTitleCount,
            totalCount: response.data.totalCount,
            statisticsData: response.data.statisticsData,
            topJobLocations: response.data.topJobLocations,
            topTitles: response.data.topTitles,
            desiredCareerPaths: response.data.desiredCareerPaths,
            experienceRange: response.data.experienceRange,
            percentageStatistics: response.data.percentageStatistics,
            topMasterDegrees: response.data.topMasterDegrees,
            sankeyData: response.data.sankeyData,
            topSkills: response.data.topSkills,
          })
        );
      }
    } catch (error) {
      console.error("Error getting desired insights:", error);
      message.error("Failed to get desired insights.");
    }
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    // Persist form data in Redux
    dispatch(setFormData(allValues));
  };

  return (
    <div>
      <Form
        layout="vertical"
        style={{ maxWidth: "80%", margin: "0 auto" }}
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleFormChange} // Track form changes
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="desiredTitle" label="Desired title">
              <Select placeholder="Select">
                {customJobOptions.map((title, index) => (
                  <Option key={index} value={title.title}>
                    {title.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="desiredCompanySize"
              label="Desired company size (optional)"
            >
              <Select placeholder="Select">
                {companySizeOptions.sort().map((company, index) => (
                  <Option key={index} value={company.company_size}>
                    {company.company_size}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="desiredCompanySector"
              label="Desired company sector (optional)"
            >
              <Select placeholder="Select">
                {companySectorsOptions
                  .sort((a, b) =>
                    a.company_sector.localeCompare(b.company_sector)
                  )
                  .map((company, index) => (
                    <Option key={index} value={company.company_sector}>
                      {company.company_sector}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="desiredCompanies"
              label="Desired companies (optional)"
            >
              <Select
                mode="multiple"
                placeholder="Select"
                onChange={(value) => console.log(value)}
              >
                {DesiredCompanyOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                ).map((company, index) => (
                  <Option key={index} value={company.value}>
                    {company.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InputComponent;
