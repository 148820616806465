import React from "react";
import Register from "../components/auth/register";
import MainLayout from "../components/layout/mainLayout";

const RegisterPage = () => {
  return (
    <MainLayout>
      <Register />
    </MainLayout>
  );
};

export default RegisterPage;
