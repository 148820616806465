import React from "react";
import { Card, Tag, Typography, Row, Col } from "antd";
import { colorPalette } from "../../config/theme";

const { Text } = Typography;

interface SuggestedSkillsProps {
  skills: { skill: string; count: number }[];
}

const SuggestedSkills: React.FC<SuggestedSkillsProps> = ({ skills }) => (
  <Card title="Suggested Skills" style={{ marginTop: 20, height: "370px" }}>
    <Text type="secondary" style={{ display: "block", marginBottom: 10 }}>
      Adding these skills to your skill set can help you stand out and improve
      your career opportunities.
    </Text>
    <Col style={{ marginTop: 20 }}>
      <Row gutter={[16, 16]}>
        {skills.map((skill, index) => (
          <Col key={skill.skill + index}>
            <Tag color={colorPalette[index % colorPalette.length]}>
              {textTransform(skill.skill)}
            </Tag>
          </Col>
        ))}
      </Row>
    </Col>
  </Card>
);

export default SuggestedSkills;

export const textTransform = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
