import React from "react";
import { Button, Card, Typography, Row, Col } from "antd";
import {
  FaChartLine,
  FaCloud,
  FaGraduationCap,
  FaRegLightbulb,
  FaSuitcase,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

interface DashboardSetupProps {
  // onComplete: () => void;
}

const options = [
  {
    title: "Generic",
    description:
      "For those who want to explore all available options and customize their journey.",
    buttonText: "Get started",
    icon: <FaRegLightbulb className="icon" />,
    iconBg: "bg-primary",
  },
  {
    title: "I know what I want to be - tell me how",
    description:
      "Get personalized guidance on achieving your specific career goals.",
    buttonText: "Get started",
    icon: <FaChartLine className="icon" />,
    iconBg: "bg-primary-2",
  },
  {
    title: "Based on my career, what are the most common pathways",
    description:
      "Discover the career paths commonly taken in your field and explore your options.",
    buttonText: "Get started",
    icon: <FaSuitcase className="icon" />,
    iconBg: "bg-primary-3",
  },
  {
    title: "In order to get where I want to get, do I need a master's degree",
    description:
      "Find out whether pursuing a master's degree is essential for your aspirations.",
    buttonText: "Get started",
    icon: <FaGraduationCap className="icon" />,
    iconBg: "bg-primary",
  },
  {
    title: "To become a successful founder, what career paths are more common",
    description:
      "Explore the career paths of successful founders and plan your journey accordingly.",
    buttonText: "Get started",
    icon: <FaSuitcase className="icon" />,
    iconBg: "bg-primary-2",
  },
  {
    title: "How do I get into FAANG?",
    description:
      "Learn the steps to break into top tech companies like Facebook, Apple, Amazon, Netflix, and Google.",
    buttonText: "Get started",
    icon: <FaCloud className="icon" />,
    iconBg: "bg-primary-3",
  },
];

const DashboardSetup: React.FC<DashboardSetupProps> = () => {
  const navigate = useNavigate();

  const handleSaveType = () => {
    navigate("/dashboard");
  };

  return (
    <div className="section choose-dashboard-type">
      <Title level={3} style={{ marginBottom: "2rem" }} className="text-black">
        Choose your type
      </Title>
      <Row gutter={[16, 16]} justify="center" className="">
        {options.map((option, index) => (
          <Col xs={24} sm={8} key={index} className="">
            <Card bordered={false}>
              <div className={`icon-container ${option.iconBg}`}>
                {option.icon}
              </div>
              <Title level={4} className="text-black card-title">
                {option.title}
              </Title>
              <Text type="secondary" className="card-description">
                {option.description}
              </Text>
              <Col>
                <Button type="primary" onClick={handleSaveType}>
                  {option.buttonText}
                </Button>
              </Col>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DashboardSetup;
