import React from "react";
import Chart from "react-apexcharts";
import { Card } from "antd";
import { ApexOptions } from "apexcharts";
import { textTransform } from "./SuggestedSkills";

interface PopularSkillsProps {
  skills: { skill: string; count: number }[];
}

const PopularSkills: React.FC<PopularSkillsProps> = ({ skills }) => {
  const options: ApexOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    labels: skills.map((skill) => textTransform(skill.skill)), // Use skill names as labels
    tooltip: {
      y: {
        formatter: (val: number) => `${val} users`, // You can modify the format here
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15, // Adjust the position of the data labels
          minAngleToShowLabel: 10, // Minimum angle to show data label (optional)
        },
      },
    },
    legend: {
      position: "bottom",
    },
  };

  const series = skills.map((skill) => skill.count); // Pie chart data (skill counts)

  return (
    <Card title="Popular Skills" style={{ marginTop: 20 }}>
      <Chart options={options} series={series} type="pie" height={300} />
    </Card>
  );
};

export default PopularSkills;
