import { Layout } from "antd";
import Sidebar from "../components/SideBar";
import Topbar from "../components/TopBar";
import { Content } from "antd/es/layout/layout";
import UserProfile from "../components/NewDashboard/UserProfile";

const UserProfilePage = () => {
  return (
    <Layout className="dashboard-layout">
      <Sidebar />
      <Layout className="main-layout" style={{ padding: 0 }}>
        <Topbar />
        <Content className="dashboard-content">
          <UserProfile />
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserProfilePage;
