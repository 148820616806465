import { Card } from "antd";
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

interface PopularRolesProps {
  roles: { title: string; count: number }[];
}

const PopularRoles: React.FC<PopularRolesProps> = ({ roles }) => {
  // Define an array of colors to use for the bars
  const colorPalette = ["#c92b2e", "#5c6bc0", "#ffb300", "#4caf50", "#f44336"];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10, // Rounded bars
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        columnWidth: "60%",
        dataLabels: {
          position: "top", // Position data labels
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: roles.map((item) => item.title),
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    fill: {
      colors: colorPalette, // Use the colorPalette array
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: number) {
          return val + "%";
        },
      },
    },
  };

  const series = [
    {
      name: "Percentage",
      data: roles.map((item) => ({
        x: item.title,
        y: item.count,
        fillColor: colorPalette[roles.indexOf(item) % colorPalette.length], // Apply custom color to each bar
      })),
    },
  ];

  return (
    <Card title="Popular Roles" style={{ marginTop: 20 }}>
      <Chart options={options} series={series} type="bar" height={320} />
    </Card>
  );
};

export default PopularRoles;
