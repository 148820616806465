export const home_path = "/";
export const login_path = "/login";
export const register_path = "/register";
export const login_app_path = "/login-app";
export const registration_path = "/sign-up";
export const reset_password_path = "/forgot-password";
export const dashboard_path = "/dashboard";
export const user_account_path = "/account";
export const demo_video_path = "/demo";
export const contact_path = "/contact";
export const terms_condition_path = "/terms-and-conditions";
export const privacy_policy_path = "/privacy-policy";
export const careermap_tool_path = "//careermapping.azkroflyz.com/";
export const mba_tool_path = "//mba.azkroflyz.com/";
export const student_tours_path = "/student-tours";
export const azkroflyz_home_path = "//azkroflyz.com";
export const azkroflyz_contact_path = "//azkroflyz.com/contact";
export const azkroflyz_student_tours_path = "//azkroflyz.com/student-tours";
export const desired_path = "/desired-path";
export const user_profile_path = "/profile";
