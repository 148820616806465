import React from "react";
import { Layout, Row, Col, Card, Button } from "antd";

import PopularRoles from "./PopularRoles";
import PopularSkills from "./PopularSkills";
import SuggestedSkills from "./SuggestedSkills";
import CareerPaths from "./CareerPaths";
import { useGetDashboardQuery } from "../../Store/Slices/DashboardApiSlice";
import WelcomeCard from "./WelcomeCard";
import {
  FaBook,
  FaChartPie,
  FaEthernet,
  FaGraduationCap,
} from "react-icons/fa";
import { Typography } from "antd";
import ApexChartDummy from "../charts/profile-dummy-chart";

import DashboardSkeleton from "./DashboardSkeleton";
import { desired_path } from "../../config/config";
import { useNavigate } from "react-router-dom";

const { Paragraph, Title } = Typography;
const { Content } = Layout;

const Dashboard: React.FC = () => {
  const { data, isLoading, error }: any = useGetDashboardQuery();
  const navigate = useNavigate();

  if (isLoading) return <DashboardSkeleton />;
  if (error || !data?.success) return <div>Error loading dashboard data.</div>;

  const dashboardData = data.data;

  return (
    <Content style={{ padding: "20px" }}>
      <Row gutter={16}>
        <Col span={14}>
          <WelcomeCard />
        </Col>

        <Col span={5}>
          <Card>
            <div className={` bg-light`}>
              <FaChartPie className="icon text-secondary" />
            </div>
            <Paragraph className="text-gray ">Tier Matching</Paragraph>
            <Title level={3} className="text-primary percentage">
              {dashboardData.tierMatchingPercentage}%
            </Title>
          </Card>
        </Col>
        <Col span={5}>
          <Card>
            <div className={` bg-light`}>
              <FaGraduationCap className="icon text-primary-2" />
            </div>
            <Paragraph className="text-gray ">Degree Matching</Paragraph>

            <Title level={3} className="text-primary percentage">
              {dashboardData.degreeMatchingPercentage}%
            </Title>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Title level={4} className="text-primary">
                  Already know what you want to become?
                </Title>
                <Paragraph className="text-gray">
                  Discover the best career path to your desired titles and
                  companies. Leverage our data-driven insights to make informed
                  career decisions and achieve your professional goals.
                </Paragraph>
              </Col>
              <Col span={24} style={{ textAlign: "left" }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate(desired_path);
                  }}
                >
                  Find Desired Paths
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <PopularRoles roles={dashboardData.popularRoles} />
        </Col>
        <Col span={10}>
          <Row gutter={[16, 16]}>
            <Col span={12} style={{ marginTop: 20 }}>
              <Card>
                <div className={` bg-light`}>
                  <FaEthernet className="icon text-primary-2" />
                </div>
                <Paragraph className="text-gray ">
                  Tier & Degree Matching
                </Paragraph>
                <Title level={3} className="text-primary percentage">
                  {dashboardData.tierAndDegreeMatchingPercentage}%
                </Title>
              </Card>
            </Col>
            <Col span={12} style={{ marginTop: 20 }}>
              <Card>
                <div className={` bg-light`}>
                  <FaBook className="icon text-primary" />
                </div>
                <Paragraph
                  className="text-gray "
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  Education Matching
                </Paragraph>
                <Title level={3} className="text-primary percentage">
                  {dashboardData.matchingEducationPercentage}%
                </Title>
              </Card>
            </Col>
          </Row>
          <Col span={24} style={{ marginTop: 20 }}>
            <Card style={{ paddingBottom: 16 }}>
              <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={12}>
                  <div>
                    <Paragraph strong>
                      Your profile matching in our database with your profile
                    </Paragraph>
                    <div style={{ marginTop: "auto" }}>
                      <Title level={3} className="text-primary-2 percentage">
                        {dashboardData.matchingEducationPercentage}%
                      </Title>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div id="profileReportChart">
                    <ApexChartDummy />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <PopularSkills skills={dashboardData.popularSkills} />
        </Col>
        <Col span={10}>
          <SuggestedSkills skills={dashboardData.suggestedSkills} />
        </Col>
      </Row>
      <CareerPaths paths={dashboardData.topCareerPaths} />
      {/* <CareerPathsSankey paths={dashboardData.topCareerPaths} /> */}
    </Content>
  );
};

export default Dashboard;
