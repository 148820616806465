import React from "react";
import { useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "../../Store/Slices/AuthApiSlice"; // Replace with your API slice path
import { Form, Input, Button, Typography, message } from "antd";
import { dashboard_path } from "../../config/config";

const { Title } = Typography;

const Register = () => {
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const { firstName, lastName, email, password, confirmPassword } = values;

    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }

    try {
      await registerUser({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      }).unwrap();
      message.success("Registration successful!");
      navigate(dashboard_path); // Redirect to login on successful registration
    } catch (err: any) {
      message.error(
        err.data?.message || "Failed to register. Please try again."
      );
    }
  };

  return (
    <div style={{ maxWidth: 600, margin: "50px auto", padding: 20 }}>
      <Title level={2} style={{ textAlign: "center" }}>
        Register
      </Title>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input placeholder="Enter your first name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: "Please input your last name!" }]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, message: "Please confirm your password!" }]}
        >
          <Input.Password placeholder="Confirm your password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            {isLoading ? "Registering..." : "Register"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;
