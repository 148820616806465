
import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./Slices/ApiSlice"
import desiredInsightsReducer from "./Slices/DesiredInsightsSlice"
import formReducer from "./Slices/InputFormSlice"



const store = configureStore({
  reducer: {
    desiredInsights: desiredInsightsReducer,
    formData: formReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware : any) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
})

export default store
