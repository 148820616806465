import React from "react";
import MainLayout from "../components/layout/mainLayout";
import ProfileSetupForm from "../components/dashboard/ProfileSetupForm";

const ProfileSetupPage = () => {
  return (
    <MainLayout>
      <ProfileSetupForm />
    </MainLayout>
  );
};

export default ProfileSetupPage;
