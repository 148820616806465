import React from "react";
import { Card, Col, Divider, Steps, Typography } from "antd";

const { Step } = Steps;
const { Paragraph, Title } = Typography;

interface CareerPathsProps {
  paths: Record<
    string,
    Array<{
      jobTitle: string;
      yearsToAcquire: string;
      jobChanges: string;
      otherMetrics: string;
    }>
  >;
}

const CareerPaths: React.FC<CareerPathsProps> = ({ paths }) => (
  <Card title="Top Career Paths" style={{ marginTop: 20 }}>
    <Paragraph className="text-gray">
      These are the potential career paths tailored to align with your
      educational background and qualifications. Explore these options to
      identify opportunities that best match your skills and aspirations
    </Paragraph>
    <Divider />
    <Col>
      {Object.entries(paths).map(([pathKey, pathDetails], index) => (
        <Col>
          <Col span={24} key={pathKey} style={{ marginBottom: "20px" }}>
            <Title
              level={4}
              className="text-primary-2"
              style={{ marginBottom: "20px" }}
            >
              Path {index + 1}
            </Title>
            <Steps
              direction="horizontal"
              size="small"
              current={Infinity} // Keeps all steps active, but only numbers will be shown
            >
              {pathDetails.map((detail, index) => (
                <Step
                  key={index}
                  title={detail.jobTitle}
                  status="process" // Ensures numbers are shown instead of tick marks
                  description={
                    <Col className="text-left text-gray">
                      <div style={{ marginBottom: "5px" }}>
                        {detail.yearsToAcquire}
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        Job Changes: {detail.jobChanges}
                      </div>
                      <div>{detail.otherMetrics}</div>
                    </Col>
                  }
                />
              ))}
            </Steps>
          </Col>
          <Divider />
        </Col>
      ))}
    </Col>
  </Card>
);

export default CareerPaths;
