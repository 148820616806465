import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Card,
  Typography,
  Checkbox,
  Col,
} from "antd";

const { Title } = Typography;
const { Option } = Select;

interface ProfileSetupProps {
  // onSubmit: (profileData: UserProfile) => void;
}

interface UserProfile {
  higherQualification: string;
  ugDegree: string;
  ugDegreeCollege: string;
  collegeTier: string;
  skills: string[];
  background: string;
  hasExperience: boolean;
  experienceDetails: string;
}

const colleges = [
  { institute_value: "Amrita Vishwa Vidyapeetham", ug_degree: "MTECH" },
  { institute_value: "Indian Institute of Management", ug_degree: "MBA" },
  { institute_value: "Shri Ram College of Commerce", ug_degree: "BCOM" },
  { institute_value: "Anna University", ug_degree: null },
  { institute_value: "IIT Guwahati", ug_degree: null },
  { institute_value: "Karpagam Academy of Higher Education", ug_degree: null },
  { institute_value: "Annamalai University", ug_degree: null },
  {
    institute_value: "Manonmaniam Sundaranar University Tirunelveli",
    ug_degree: "MSC",
  },
];

const ProfileSetupForm: React.FC<ProfileSetupProps> = () => {
  const [filteredColleges, setFilteredColleges] = useState<string[]>([]);
  const [ugDegree, setUgDegree] = useState<string>("");

  const [form] = Form.useForm();

  useEffect(() => {
    const filteredData = colleges.filter(
      (entry) => entry.ug_degree !== null && entry.ug_degree === ugDegree
    );

    // Extract unique college names from the filtered data
    const uniqueColleges = Array.from(
      new Set(filteredData.map((entry) => entry.institute_value))
    );

    setFilteredColleges(uniqueColleges);
  }, [ugDegree]);

  const handleFormSubmit = (values: UserProfile) => {
    // onSubmit(values);
  };

  return (
    <Col className="profile-setup-container container">
      <Col className="section">
        <Card bordered={false} style={{ maxWidth: "700px", margin: "0 auto" }}>
          <Title level={3} className="text-black section-title">
            Setup your profile to get started
          </Title>
          <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
            <Form.Item
              label="Higher Qualification"
              name="higherQualification"
              rules={[
                {
                  required: true,
                  message: "Please enter your higher qualification!",
                },
              ]}
            >
              <Input placeholder="Enter your higher qualification (e.g., MTECH, MBA)" />
            </Form.Item>
            <Form.Item
              label="UG Degree"
              name="ugDegree"
              rules={[
                { required: true, message: "Please select your UG degree!" },
              ]}
            >
              <Select
                placeholder="Select your UG degree"
                onChange={(value) => setUgDegree(value)}
                style={{ width: "100%" }}
              >
                <Option value="MTECH">MTECH</Option>
                <Option value="MBA">MBA</Option>
                <Option value="BCOM">BCOM</Option>
                <Option value="MSC">MSC</Option>
                {/* Add more UG degrees as needed */}
              </Select>
            </Form.Item>
            <Form.Item
              label="UG Degree College"
              name="ugDegreeCollege"
              rules={[
                {
                  required: true,
                  message: "Please select your UG degree college!",
                },
              ]}
            >
              <Select
                placeholder="Select your UG degree college"
                style={{ width: "100%" }}
              >
                {filteredColleges.map((college) => (
                  <Option key={college} value={college}>
                    {college}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="College Tier"
              name="collegeTier"
              rules={[
                { required: true, message: "Please enter the college tier!" },
              ]}
            >
              <Input placeholder="Enter the tier of your college (e.g., Tier 1, Tier 2)" />
            </Form.Item>
            <Form.Item
              label="Skills"
              name="skills"
              rules={[
                { required: true, message: "Please select your skills!" },
              ]}
            >
              <Select
                mode="tags"
                placeholder="Enter or select your skills"
                style={{ width: "100%" }}
              >
                <Option value="JavaScript">JavaScript</Option>
                <Option value="Python">Python</Option>
                <Option value="React">React</Option>
                <Option value="TypeScript">TypeScript</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Background"
              name="background"
              rules={[
                { required: true, message: "Please enter your background!" },
              ]}
            >
              <Input placeholder="Enter your background (e.g., Developer, Designer)" />
            </Form.Item>
            <Form.Item name="hasExperience" valuePropName="checked">
              <Checkbox>Do you have experience?</Checkbox>
            </Form.Item>
            {form.getFieldValue("hasExperience") && (
              <Form.Item
                label="Experience Details"
                name="experienceDetails"
                rules={[
                  {
                    required: true,
                    message: "Please enter your experience details!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Describe your work experience" />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ borderRadius: "8px" }}
              >
                Save Profile
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Col>
  );
};

export default ProfileSetupForm;
