export const BASE_URL = "https://backend.azkroflyz.com";
// export const BASE_URL = "http://localhost:8003";




export const USERS_URL = "/api/user";
export const AUTH_URL = "/api/auth";
export const ADMIN_URL = "/api/admin";
export const GMAT_URL = "/api/gmat";
export const DASHBOARD_URL = "/api/dashboard";







export const experienceOptions = ['0 - 2', '2 - 4', '4 - 8', '8 - 10', '10+'];
