import React from "react";
import { Layout, Input, Avatar, Dropdown, Menu } from "antd";
import { FaSearch, FaSignOutAlt, FaUser } from "react-icons/fa";

const { Header } = Layout;

const Topbar: React.FC = () => {
  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <div className="profile-item">
          <div className="profile-info">
            <div className="profile-name">John Doe</div>
            <div className="profile-role">Admin</div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="my-profile">
        <FaUser />
        <span className="menu-item-text">My Profile</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <FaSignOutAlt />
        <span className="menu-item-text">Log Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="topbar">
      {/* Search Input */}
      <div className="search-container invisible">
        <FaSearch className="search-icon" />
        <Input placeholder="Search..." className="search-input" />
      </div>

      {/* User Dropdown */}
      <Dropdown overlay={userMenu} trigger={["click"]}>
        <div className="user-dropdown">
          <Avatar src="../assets/img/avatars/1.png" size="large" />
        </div>
      </Dropdown>
    </Header>
  );
};

export default Topbar;
