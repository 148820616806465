import React from "react";
import {
  FaUserTie,
  FaDatabase,
  FaIndustry,
  FaLaptopCode,
  FaChartBar,
  FaLightbulb,
  FaRoad,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { Button, Card, Col, Row, Typography } from "antd";
import { dashboard_path, login_path } from "../config/config";
import MainLayout from "../components/layout/mainLayout";

const { Paragraph, Title } = Typography;

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <MainLayout>
        <div
          className=" pb-0 px-0 "
          style={{
            background: "white",
          }}
        >
          <Col className="container hero">
            <Row justify="start" align="middle" gutter={[0, 16]}>
              <Col xs={24} md={15}>
                <Title level={1} style={{ color: "white" }}>
                  Navigate your career journey with
                  <span className="text-secondary"> precision and purpose</span>
                </Title>

                <Button
                  type="primary"
                  size="large"
                  onClick={() => navigate(login_path)}
                >
                  Get started
                </Button>
              </Col>
              <Col xs={24} md={12}></Col>
            </Row>
          </Col>

          {/* <section
            id="about"
            className="about m-0"
            style={{ background: "rgb(0,0,0,0.)", paddingTop: "80px" }}
          >
            <div className="container p-0">
              <div className="row no-gutters p-0 ">
                <div
                  className="content container col-xl-7 "
                  style={{ display: "grid", placeItems: "center" }}
                >
                  <div className="content  pr-3">
                    <h2
                      style={{
                        textAlign: "center",

                        color: "white",
                      }}
                      className=" mb-5"
                      data-aos="zoom-out"
                      data-aos-once="true"
                    >
                      Are you a college student?
                    </h2>
                    <div className="d-flex justify-content-center">
                      <h2
                        className="mb-5  col-10"
                        style={{ color: "rgb(41, 40, 40,0.8)" }}
                        data-aos="zoom-out"
                        data-aos-delay="500"
                        data-aos-once="true"
                      >
                        {" "}
                        Navigate your career journey with precision and purpose
                      </h2>
                    </div>
                    <button
                      onClick={() => navigate(demo_video_path)}
                      className="custom-demo-btn mt-3 m-3 "
                    >
                      Watch Demo
                    </button>
                    <button
                      onClick={() => navigate(dashboard_path)}
                      className="custom-demo-btn m-3 "
                    >
                      <div className="d-flex justify-content-center align-items-start">
                        <span> Get started</span>

                        <span style={{ marginLeft: "8px", fontSize: "18px" }}>
                          <ArrowRightOutlined />
                        </span>
                      </div>
                    </button>{" "}
                    <div
                      className="d-lg-flex "
                      style={{
                        width: "100%",
                        textAlign: "start",

                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <a href="#contact" className="btn-get-started scrollto">
                    Use Free Profile Evaluator
                  </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 d-flex align-items-stretch">
                  <div className="icon-boxes d-flex flex-column justify-content-center">
                    <img
                      style={{ width: "90%" }}
                      src={collegeStudentImg}
                      alt="college student"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <WhyChooseUsSection />

          {/* <CareerReportsSection /> */}
          <HowAzkroflyzBringsValue />

          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#f4bc4c"
              fill-opacity="1"
              d="M0,224L80,202.7C160,181,320,139,480,144C640,149,800,203,960,202.7C1120,203,1280,149,1360,122.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg> */}
        </div>
      </MainLayout>
    </>
  );
};

export default HomePage;

const WhyChooseUsSection = () => {
  return (
    <section className="py-5 mb-5 container">
      <div className="section">
        <div className="icon-boxes d-flex flex-column justify-content-center">
          <div className="" style={{ marginBottom: "30px" }}>
            <Title level={2}>
              <span className="text-secondary"> WHY</span> CHOOSE US
            </Title>
          </div>

          <Row gutter={[16, 24]} className=" p-0">
            {/* Card 1 */}
            <Col xs={24} md={12}>
              <Card
                data-aos="slide-up"
                data-aos-delay="100"
                data-aos-once="true"
                className="text-left"
              >
                <div className="icon-container mb-3 bg-primary">
                  <FaUserTie className="icon  " />
                </div>
                <Title level={3}>Personalized Career Insights</Title>
                <p>
                  Tailored advice based on individual educational details and
                  career preferences
                </p>
              </Card>
            </Col>

            {/* Card 2 */}
            <Col xs={24} md={12}>
              <Card
                data-aos="slide-up"
                data-aos-delay="200"
                data-aos-once="true"
                className=""
              >
                <div className="icon-container mb-3 bg-primary-2">
                  <FaDatabase className="icon " />
                </div>
                <Title level={3}>Comprehensive Career Data</Title>
                <p>
                  A vast database offering insights into various industries,
                  companies, and sectors.
                </p>
              </Card>
            </Col>

            {/* Card 3 */}
            <Col xs={24} md={12}>
              <Card
                data-aos="slide-up"
                data-aos-delay="300"
                data-aos-once="true"
                className=""
              >
                <div className="icon-container mb-3  bg-primary-3">
                  <FaIndustry className="icon " />
                </div>
                <Title level={3}>Current Industry Trends</Title>
                <p>
                  Stay updated on the latest trends and changes in different
                  industries.
                </p>
              </Card>
            </Col>

            {/* Card 4 */}
            <Col xs={24} md={12}>
              <Card
                data-aos="slide-up"
                data-aos-delay="400"
                data-aos-once="true"
                className=""
              >
                <div className="icon-container mb-3 bg-primary">
                  <FaLaptopCode className="icon " />
                </div>
                <Title level={3}>User-Friendly Interface</Title>
                <p>
                  Intuitive and user-friendly tool for a seamless experience.
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

// const CareerReportsSection = () => {
//   return (
//     <section className="mb-5 section-bg">
//       <div className="container">
//         <Row justify="space-between" gutter={[24, 24]}>
//           {/* Card 1 */}
//           <Col xs={24} lg={12}>
//             <Card
//               bordered={false}
//               className="bg-light"
//               bodyStyle={{ padding: 0 }}
//             >
//               <div
//                 style={{
//                   background: `url(${laptopImg})`,
//                   height: "400px",
//                   backgroundPosition: "center",
//                   backgroundSize: "contain",
//                   backgroundRepeat: "no-repeat",
//                   position: "relative",
//                 }}
//                 data-aos="fade-up"
//                 data-aos-delay="200"
//                 data-aos-once="true"
//               >
//                 <div
//                   className="laptop-bg"
//                   style={{
//                     position: "absolute",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                   }}
//                 >
//                   <img
//                     src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1707483457/dxxzwdkb2yoiktadohvv.png"
//                     alt="career insights"
//                   />
//                 </div>
//               </div>
//               <div
//                 style={{
//                   display: "grid",
//                   placeItems: "center",
//                   padding: "20px",
//                 }}
//                 data-aos="fade-up"
//                 data-aos-delay="300"
//                 data-aos-once="true"
//               >
//                 <Title level={3} className="text-primary col-8 text-start">
//                   Empowering Your Career Journey with Insightful Reports
//                 </Title>
//                 <Paragraph className="col-8 text-start ">
//                   Our reports help you understand the current job market. Get
//                   insights on the skills in demand, helping you make informed
//                   decisions for your career. Our goal is to provide
//                   straightforward information to guide you in acquiring the
//                   skills needed for a successful professional journey aligned
//                   with your goals.
//                 </Paragraph>
//               </div>
//             </Card>
//           </Col>

//           {/* Card 2 */}
//           <Col xs={24} lg={12}>
//             <Card
//               bordered={false}
//               className="bg-light"
//               bodyStyle={{ padding: 0 }}
//             >
//               <div
//                 style={{
//                   background: `url(${laptopImg})`,
//                   height: "400px",
//                   backgroundPosition: "center",
//                   backgroundSize: "contain",
//                   backgroundRepeat: "no-repeat",
//                   position: "relative",
//                 }}
//                 data-aos="fade-up"
//                 data-aos-delay="200"
//                 data-aos-once="true"
//               >
//                 <div
//                   className="laptop-bg"
//                   style={{
//                     position: "absolute",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                   }}
//                 >
//                   <img
//                     src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1707741324/zu6occpbgzbsjuv1aaf8.png"
//                     alt="career insights"
//                   />
//                 </div>
//               </div>
//               <div
//                 style={{
//                   display: "grid",
//                   placeItems: "center",
//                   padding: "20px",
//                 }}
//                 data-aos="fade-up"
//                 data-aos-delay="300"
//                 data-aos-once="true"
//               >
//                 <Title level={3} className="text-primary col-8 text-start">
//                   Informed Career Decisions with Deep Dive Reports
//                 </Title>
//                 <Paragraph className="col-8 text-start ">
//                   Delve into our reports for a comprehensive exploration of the
//                   current professional landscape. Our detailed analysis is
//                   designed to empower you with the insights needed to make
//                   well-informed decisions regarding your career path. By
//                   leveraging our reports, you gain a deeper understanding of
//                   industry trends, ensuring your choices align strategically
//                   with your career goals.
//                 </Paragraph>
//               </div>
//             </Card>
//           </Col>
//         </Row>
//       </div>
//     </section>
//   );
// };

const HowAzkroflyzBringsValue = () => (
  <section className="how-azkroflyz-brings-value container mb-5">
    <div className="section">
      <div className="" style={{ marginBottom: "30px" }}>
        <Title level={2}>
          <span className="text-secondary"> HOW DOES</span> AZKROFLYZ BRING
          VALUE
        </Title>
      </div>
      <Row gutter={[16, 16]} className="card-container">
        <Col xs={24} md={8}>
          <Card data-aos="slide-up" data-aos-delay="100" data-aos-once="true">
            <div className="icon-container bg-primary mb-3">
              <FaChartBar className="icon" />
            </div>
            <Title level={3} className="card-title">
              Skill Gap Analysis
            </Title>
            <Paragraph className="card-text">
              Identify key skills needed for success in your chosen field. Our
              reports highlight any skill gaps and offer recommendations for
              professional development, ensuring you stay competitive in the job
              market.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card data-aos="slide-up" data-aos-delay="200" data-aos-once="true">
            <div className="icon-container bg-primary-2 mb-3">
              <FaLightbulb className="icon" />
            </div>
            <Title level={3} className="card-title">
              Job Role Projections
            </Title>
            <Paragraph className="card-text">
              Explore future job roles and emerging opportunities within your
              preferred sector. Our insights help you stay ahead of industry
              trends, allowing you to position yourself for success.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card data-aos="slide-up" data-aos-delay="300" data-aos-once="true">
            <div className="icon-container bg-primary-3 mb-3">
              <FaRoad className="icon" />
            </div>
            <Title level={3} className="card-title">
              Tailored Career Roadmap
            </Title>
            <Paragraph className="card-text">
              Discover a personalized roadmap tailored to your educational
              background and career aspirations. Our reports outline the steps
              to achieve your professional goals.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  </section>
);
