import { apiSlice } from "./ApiSlice"
import {  DASHBOARD_URL } from "../../config/Constants"

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getDashboard: builder.query<void, void>({
      query: () => ({
        url: `${DASHBOARD_URL}/dashboard`,
      }),
      keepUnusedDataFor: 5,
   }),

   getDesiredInsights: builder.mutation({
    query: (data) => ({
      url: `${DASHBOARD_URL}/desired-insights`,
      method: "POST",
      body: data, 
    }),
  }), 

  }),


   
})

export const {

  useGetDashboardQuery,
  useGetDesiredInsightsMutation
} = dashboardApiSlice
