import { useState } from "react";

import Topbar from "../components/TopBar";
import { Card, Layout } from "antd";
import Sidebar from "../components/SideBar";
import InputComponent from "../components/dashboard/input";
import OutputComponent from "../components/dashboard/output";

const { Content } = Layout;

const DesiredPathPage: React.FC = () => {
  //eslint-disable-next-line
  const [_isResultsOpen, setIsResultsOpen] = useState<boolean>(true);

  return (
    <Layout className="dashboard-layout">
      <Sidebar />
      <Layout className="main-layout" style={{ padding: 0 }}>
        <Topbar />
        <Content className="dashboard-content">
          <Card>
            <InputComponent />
          </Card>

          <OutputComponent />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DesiredPathPage;
