import React from "react";
import MainLayout from "../components/layout/mainLayout";
import DashboardSetup from "../components/dashboard/DashboardSetup";
import { Col } from "antd";

const ChooseDashboardType = () => {
  return (
    <MainLayout>
      <Col className=" text-left ">
        <DashboardSetup />
      </Col>
    </MainLayout>
  );
};

export default ChooseDashboardType;
