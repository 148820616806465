import React from "react";
import Dashboard from "../components/NewDashboard/Dashboard";
import Sidebar from "../components/SideBar";
import { Layout } from "antd";
import Topbar from "../components/TopBar";

const { Content } = Layout;

const DashboardPageNew = () => {
  return (
    <Layout className="dashboard-layout">
      <Sidebar />
      <Layout className="main-layout" style={{ padding: 0 }}>
        <Topbar />
        <Content className="dashboard-content">
          <Dashboard />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPageNew;
