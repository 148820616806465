import React from "react";
import { Card, Row, Col, Typography } from "antd";

const { Title, Text } = Typography;

const WelcomeCard = () => {
  return (
    <Card className="welcome-card">
      <Row align="middle">
        <Col xs={24} sm={14}>
          <div className="card-content">
            <Title level={5} className="text-primary">
              Welcome user! 🎉
            </Title>
            <Text className="description text-gray">
              Discover personalized career insights to help you navigate your
              journey, explore opportunities, and achieve your professional
              aspirations with confidence
            </Text>
          </div>
        </Col>
        <Col xs={24} sm={10} className="image-container">
          <img
            src="../assets/img/illustrations/man-with-laptop-light.png"
            height="140"
            alt="View Badge User"
            className="welcome-image"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default WelcomeCard;
