import { createSlice } from "@reduxjs/toolkit";

const desiredInsightsSlice = createSlice({
  name: "desiredInsights",
  initialState: {
    desiredTitle: "",
    desiredTitleCount: 0,
    totalCount: 0,
    statisticsData: [],
    topJobLocations: [],
    topTitles: [],
    desiredCareerPaths: [],
    experienceRange: "",
    percentageStatistics: {},
    topMasterDegrees: [],
    sankeyData: {},
    topSkills: [],
  },
  reducers: {
    setDesiredInsights: (state, action) => {
      state.desiredTitle = action.payload.desiredTitle;
      state.desiredCareerPaths = action.payload.desiredCareerPaths;
      state.experienceRange = action.payload.experienceRange;   
      state.topMasterDegrees = action.payload.topMasterDegrees;
      state.topJobLocations = action.payload.topJobLocations;
      state.topTitles = action.payload.topTitles;
      state.desiredTitleCount = action.payload.desiredTitleCount;
      state.totalCount = action.payload.totalCount;
      state.statisticsData = action.payload.statisticsData;
      state.percentageStatistics = action.payload.percentageStatistics;
      state.sankeyData = action.payload.sankeyData;
      state.topSkills = action.payload.topSkills;
    },
    resetDesiredInsights: (state) => {
      state.desiredTitle = "";
      state.desiredCareerPaths = [];
      state.experienceRange = "";
      state.topMasterDegrees = [];
      state.topJobLocations = [];
      state.topTitles = [];
      state.desiredTitleCount = 0;
      state.totalCount = 0;
      state.statisticsData = [];
      state.percentageStatistics = {};
      state.sankeyData = {};
      state.topSkills = [];
    },
  },
});

export const { setDesiredInsights, resetDesiredInsights } = desiredInsightsSlice.actions;
const desiredInsightsReducer = desiredInsightsSlice.reducer;
export default desiredInsightsReducer;
