import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "formData",
  initialState: {}, // Start with an empty object
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload }; // Update form values
    },
    clearFormData: () => {
      return {}; // Reset form data
    },
  },
});

export const { setFormData, clearFormData } = formSlice.actions;
const formReducer = formSlice.reducer;
export default formReducer;
