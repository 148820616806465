import { apiSlice } from "./ApiSlice"
import { USERS_URL } from "../../config/Constants"

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getUserDetails: builder.query<void, void>({
      query: () => ({
        url: `${USERS_URL}`,
      }),
      keepUnusedDataFor: 5,
   }),

   getUserProfile: builder.query<void, void>({
    query: () => ({
      url: `${USERS_URL}/profile`,
    }),
   }),


    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: "PUT",
        body: data,
      }),
    }),

    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${USERS_URL}/${id}`,
        method: "DELETE",
      }),
    }),

  }),
})

export const {
 useLazyGetUserDetailsQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,

} = userApiSlice
