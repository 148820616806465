import { fetchBaseQuery, createApi, FetchArgs, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../config/Constants';


const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('accessToken');


    if (accessToken) {
      headers.set('x-access-token', accessToken);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, unknown> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);


  if (result.error) {
    const status = result.error.status;


    // Handle 401 Unauthorized (trigger session modal)
    if (status === 401) {
      localStorage.removeItem('auth');
      // window.location.href = '/login'; 
    }

    // Handle 403 Forbidden (trigger subscription or tokens modal)
    if (status === 405) {
      // @ts-ignore
      const errorMsg = result.error?.data?.error_msg 

      // Check the error message for specific conditions
      if (errorMsg === "Subscription required") {
        // triggerSubscriptionModal(); // Opens a modal about missing subscription
      } else if (errorMsg === "Tokens required") {
        // triggerTokenModal(); // Opens a modal about missing tokens
      }
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: () => ({}),
});