import React from "react";
import { Divider, Layout, Menu, Typography } from "antd";

import { useNavigate, useLocation } from "react-router-dom";
import { home_path } from "../config/config";
import { FaChartPie, FaRoute, FaUser } from "react-icons/fa";

const { Sider } = Layout;

const { Title } = Typography;
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current path

  // Define the mapping between paths and menu keys
  const menuKeyMapping: { [key: string]: string } = {
    "/dashboard": "1",
    "/desired-path": "2",
    "/profile": "3",
  };

  // Determine the active menu key based on the current path
  const activeMenuKey = menuKeyMapping[location.pathname] || "1"; // Default to "1" if the path doesn't match

  return (
    <Sider collapsible={false} className="sidebar">
      <div
        className="app-brand "
        onClick={() => navigate(home_path)}
        style={{ cursor: "pointer" }}
      >
        <Title level={2} className="text-primary app-brand-link">
          Azkroflyz
        </Title>

        <Divider />
      </div>

      <Menu theme="light" mode="inline" selectedKeys={[activeMenuKey]}>
        <Menu.Item
          key="1"
          icon={<FaChartPie />}
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<FaRoute />}
          onClick={() => navigate("/desired-path")}
        >
          Desired Path
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<FaUser />}
          onClick={() => navigate("/profile")}
        >
          Profile
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
